<template>
  <div>
    <v-row v-if="isLoading" class="justify-center align-center" style="min-height: 50vh">
      <v-col cols="auto">
        <v-progress-circular :size="70" indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>
    <div v-show="!isLoading">
      <v-row align="center" justify="center">
        <v-col cols="12" md="12" class="align-self-end mb-sm-2 mt-2">
          <v-card class="ps-3 mx-1 rounded-xl" flat>
            <v-row>
              <v-col cols="12" lg="12" sm="11" md="9">
                <v-card-title class="page-title flex-nowrap text-2xl mt-0 pt-2 px-1 align-center">
                  <div class="d-flex align-center">
                    <v-avatar size="40" class="v-avatar-light-bg primary--text me-2">
                      <v-icon size="25" color="primary">
                        {{ icons.mdiCalendarClock }}
                      </v-icon>
                    </v-avatar>
                    <span class="text-no-wrap font-weight-bold">{{ $t('actionTiming') }}</span>
                  </div>
                </v-card-title>

                <v-card-text class="pt-1 py-3">
                  <v-row>
                    <div class="pb-1 d-flex">
                      {{ $t('selectActivityDays') }}
                    </div>
                  </v-row>
                  <v-row v-for="(item, index) in $t('activityDays')" :key="index">
                    <div class="pb-1 d-flex">
                      <div>
                        <v-icon class="me-1" color="primary" size="20">
                          {{ icons.mdiClockOutline }}
                        </v-icon>
                      </div>
                      <div>
                        <span>{{ item }}</span>
                      </div>
                    </div>
                  </v-row>
                </v-card-text>
              </v-col>
              <v-col cols="12" sm="12" md="3" class="pa-0">
                <div>
                  <v-img
                    width="140"
                    src="@/assets/images/3d-characters/pose-fs-9.png"
                    class="gamification-john-pose-2"
                  ></v-img>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-card outlined class="mt-5 rounded-xl" color="transparent" flat>
        <scheduling-cards @loadingComplete="loadingComplete"></scheduling-cards>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mdiClockOutline, mdiCalendarClock } from '@mdi/js'
import SchedulingCards from './SchedulingCards.vue'
import { ref } from '@vue/composition-api'

export default {
  components: { SchedulingCards },
  setup() {
    const isLoading = ref(true)
    const loadingComplete = () => {
      isLoading.value = false
    }

    return {
      loadingComplete,
      isLoading,
      icons: {
        mdiClockOutline,
        mdiCalendarClock,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.gamification-john-pose-2 {
  position: absolute;
}

.gamification-john-pose-2 {
  bottom: 0;
  @include ltr() {
    right: 2%;
  }
  @include rtl() {
    left: 2%;
  }
}
@media (max-width: 800px) {
  .gamification-john-pose-2 {
    max-width: 90px;
    bottom: 0;
    @include ltr() {
      right: 0%;
    }
    @include rtl() {
      left: 0%;
    }
  }
}

@media (max-width: 600px) {
  .gamification-john-pose-2 {
    display: none;
  }
}

@media (max-width: 400px) {
  .page-title {
    font-size: 1.2rem !important;
  }
}
</style>
